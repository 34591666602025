import { Icon } from '@gitbook/icons';
import React from 'react';

import { t, useLanguage } from '@/intl/client';
import { tcls } from '@/lib/tailwind';

import { useSearchLink } from './useSearch';
import { Link } from '../primitives';

export const SearchQuestionResultItem = React.forwardRef(function SearchQuestionResultItem(
    props: {
        question: string;
        active: boolean;
        onClick: () => void;
        recommended?: boolean;
    },
    ref: React.Ref<htmlanchorelement>,
) {
    const { question, recommended = false, active, onClick } = props;
    const language = useLanguage();
    const getLinkProp = useSearchLink();

    return (
        <link ref="{ref}" onClick="{onClick}" className="{tcls(" 'flex',="" 'flex-row',="" 'px-4',="" 'py-2',="" 'hover:bg-dark-4="" 2',="" 'text-dark="" 7',="" 'text-sm',="" 'font-medium',="" 'first:mt-0',="" 'last:pb-3',="" 'dark:text-light="" 8',="" 'dark:hover:bg-light-4="" active="" ?="" ['bg-dark="" 1',="" 'dark:bg-light="" 1']="" :="" null,="" )}="" {...getLinkProp({="" ask:="" true,="" query:="" question,="" })}="">
            <icon icon="magnifying-glass" className="{tcls(" 'w-[15px]',="" 'h-[15px]',="" 'shrink-0',="" 'mt-0.5',="" 'mr-4',="" 'text-dark="" 5',="" 'dark:text-light="" )}=""></icon>
            {recommended ? question : t(language, 'search_ask', [question])}
        
    );
});
</htmlanchorelement>